@font-face {
  font-family: "Inter";
  src: url(./fonts/InterVariable/Inter-VariableFont_slntwght.ttf)
    format("truetype-variations");
  font-weight: "100 1000";
}

@font-face {
  font-family: "RFDewiExpanded";
  src: url(./fonts/Dewi/RFDewiExpanded-Semibold.woff2) format("woff2");
  font-weight: "600";
}

.padre-no-scroll::-webkit-scrollbar {
  display: none;
}
.padre-no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  overscroll-behavior: none;
}
